import React from 'react';
import './App.css';

function Home() {
  return (
    <div className="App">
      <main className="App-main">
        <section className="App-info">
          <h2>Suporte</h2>
          <p>Bem-vindo à página de suporte da Barbearia Filadelfo. Estamos aqui para ajudar!</p>
          <p>Para qualquer dúvida ou problema, entre em contato conosco através dos seguintes meios:</p>
        </section>
        <section className="App-contact">
          <h3>Contato</h3>
          <p>Email: <a href="mailto:contato@barbeariafiladelfo.com">contato@barbeariafiladelfo.com.br</a></p>
          <p>WhatsApp: <a href="https://wa.me/5517991297360" target="_blank" rel="noopener noreferrer">(17) 99129-7360</a></p>
        </section>
      </main>
      <footer className="App-footer">
        <p>&copy; 2024 Barbearia Filadelfo. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
}

export default Home;
