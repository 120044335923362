import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <h1>Política de Privacidade - Barbearia Filadelfo</h1>
      <h2>Introdução</h2>
      <p>
        A Barbearia Filadelfo ("nós") valoriza a sua privacidade. Esta Política de Privacidade explica como coletamos, usamos,
        compartilhamos e protegemos suas informações pessoais relativas ao uso do aplicativo Barbearia Filadelfo.
      </p><br/>
      <h2>Informações que Coletamos</h2>
      <p>
        <strong>Informações da Conta:</strong> Ao criar uma conta, coletamos seu endereço de e-mail, número de telefone, nome completo e, opcionalmente, 
        uma foto de perfil e CPF.
      </p>
      <p>
        <strong>Informações de Pagamento:</strong> Se você optar por pagar com cartão de crédito, seus dados do cartão de crédito são coletados 
        e processados com segurança pelo nosso processador de pagamentos terceirizado, o Mercado Pago.
      </p>
      <p>
        <strong>Informações de Agendamento:</strong> Coletamos informações relacionadas aos seus agendamentos, incluindo os serviços escolhidos 
        e os custos associados.
      </p>
      <p>
        <strong>Dados de Uso:</strong> Usamos o Google Analytics para coletar informações sobre como você interage com o nosso aplicativo. 
        Isso nos ajuda a analisar padrões de uso e melhorar a funcionalidade do aplicativo.
      </p><br/>
      <h2>Como Usamos suas Informações</h2>
      <p>
        <strong>Fornecimento de Serviços:</strong> Usamos suas informações para criar sua conta, facilitar e agendar seus atendimentos, 
        processar pagamentos e para fins de identificação do cliente.
      </p>
      <p>
        <strong>Comunicação:</strong> Com a sua permissão, podemos usar seu endereço de e-mail e telefone para lhe enviar notificações e atualizações 
        relacionadas ao aplicativo e seus agendamentos.
      </p>
      <p>
        <strong>Melhoria do Aplicativo:</strong> Os dados de uso coletados através do Google Analytics nos ajudam a entender como o nosso aplicativo 
        é usado e identificar áreas de melhoria.
      </p>
      <p>
        <strong>Processamento de Pagamentos:</strong> Seu nome, CPF e informações de pagamento são compartilhados com o Mercado Pago para processar 
        suas transações com segurança.
      </p><br/>
      <h2>Compartilhamento das Suas Informações</h2>
      <p>
        <strong>Barbearia Filadelfo:</strong> Compartilhamos seu nome, telefone, e-mail e informações de agendamento com a Barbearia Filadelfo para que 
        eles possam prestar serviços e gerenciar seus relacionamentos com os clientes.
      </p>
      <p>
        <strong>Mercado Pago:</strong> Compartilhamos as informações necessárias com o Mercado Pago para facilitar o processamento seguro de pagamentos.
      </p>
      <p>
        <strong>Conformidade Legal:</strong> Podemos divulgar suas informações conforme exigido por lei, ordem judicial ou outros processos legais.
      </p><br/>
      <h2>Suas Escolhas</h2>
      <p>
        <strong>Exclusão da Conta:</strong> Você pode excluir sua conta a qualquer momento dentro do aplicativo. Isso irá cancelar todos os seus agendamentos e removerá suas informações pessoais de nossos sistemas.
      </p><br/>
      <h2>Segurança</h2>
      <p>
        Implementamos medidas para proteger suas informações contra acesso não autorizado. Lembre-se, entretanto, que a transmissão de dados pela Internet 
        nunca é totalmente segura. Embora façamos o máximo para garantir sua segurança, não podemos garanti-la completamente.
      </p><br/>
      <h2>Privacidade Infantil</h2>
      <p>
        Nosso aplicativo não se destina a crianças menores de 14 anos. Não coletamos intencionalmente informações pessoais de crianças.
      </p><br/>
      <h2>Alterações a esta Política</h2>
      <p>
        Podemos atualizar esta Política de Privacidade periodicamente. Iremos notificá-lo através do aplicativo ou e-mail quando forem feitas alterações.
      </p><br/>
      <h2>Entre em Contato</h2>
      <p>
        Em caso de dúvidas, entre em contato conosco através do <a href="mailto:contato@barbeariafiladelfo.com.br">contato@barbeariafiladelfo.com.br</a>.
      </p>
      <br/><br/><br/><br/>
    </div>
  );
}

export default PrivacyPolicy;
