import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import AccountDelete from './AccountDelete';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Barbearia Filadelfo</h1>
        </header>
        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/politica-privacidade" element={<PrivacyPolicy />} />
            <Route path="/termos-de-uso" element={<TermsOfUse />} />
            <Route path="/deletar-conta" element={<AccountDelete />} />
          </Routes>
        </main>
        <footer className="App-footer">
          <p>&copy; 2024 Barbearia Filadelfo. Todos os direitos reservados.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
