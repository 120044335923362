import React from 'react';

function TermosDeUso() {
  return (
    <div className="terms-container">
      <h1>Termos de Uso do Aplicativo "Barbearia Filadelfo".</h1>

          <h2>1. Uso Responsável: </h2>
          <p>
            O usuário se compromete a utilizar o aplicativo para fins legítimos, 
            fornecendo informações pessoais verdadeiras e completas. 
            O cadastro com dados falsos ou incompletos viola estes termos.
          </p><br/>

          <h2>2. Coleta de Dados: </h2>
          <p>
            Para garantir a segurança e o bom funcionamento dos serviços, 
            coletamos dados pessoais como nome, número de telefone, CPF, entre outros. 
            Estas informações são utilizadas para confirmar sua identidade, 
            processar pagamentos e prevenir fraudes.
          </p><br/>

          <h2>3. Cancelamento e Reagendamento:</h2>
          <p>
            Após criar e pagar um agendamento pelo aplicativo, o usuário poderá 
            cancelá-lo ou reagendá-lo com até 6 horas de antecedência do horário 
            marcado. Após este prazo, o reembolso não será possível caso o 
            usuário não compareça ou cancele o serviço.
          </p><br/>

          <h2>4. Exclusão da Conta:</h2>
          <p>
            A exclusão da conta do usuário resultará no cancelamento de todos 
            os seus agendamentos. Caso haja agendamentos dentro do prazo de 
            6 horas, a regra de cancelamento e reembolso se aplicará.
          </p><br/>

          <h2>5. Reembolso em Cancelamentos:</h2>
          <p>
            Em caso de cancelamento com mais de 6 horas de antecedência do horário 
            agendado, o usuário receberá o reembolso integral do valor pago.
          </p><br/>
          
          <h2>6. Disposições Gerais:</h2>
            <p className="disclaimer">
              Ao utilizar nosso aplicativo, você concorda com estes termos de uso. 
            Reservamo-nos o direito de atualizar estes termos a qualquer momento, 
            e as alterações serão comunicadas aos usuários através do aplicativo.
          </p><br/>

      <h2>Entre em Contato</h2>
      <p>
        Em caso de dúvidas, entre em contato conosco através do <a href="mailto:contato@barbeariafiladelfo.com.br">contato@barbeariafiladelfo.com.br</a>.
      </p>
      <br/><br/><br/><br/>
    </div>
  );
}

export default TermosDeUso;
