import React from 'react';
import './App.css';

function Home() {
  return (
    <div className="App">
      <main className="App-main">
        <section className="App-info">
          <h2>Deletando sua Conta</h2>
          <p>Para deletar sua conta no aplicativo basta acessar a aba "Perfil", clicar no ícone de "Mais Informações" no canto superior direito e selecionar a opção "Excluir Conta".</p>
          <p>Caso não tenha mais acesso ao aplicativo, é possível solicitar a exclusão dos seus dados enviando um email para: <a href="mailto:contato@barbeariafiladelfo.com">contato@barbeariafiladelfo.com.br</a></p>
          <p>Ao excluir sua conta, todos os agendamentos serão cancelados e todos os seus dados serão excluídos de forma permanente.</p>
        </section>
        <section className="App-contact">
          <h3>Nossos contatos:</h3>
          <p>Email: <a href="mailto:contato@barbeariafiladelfo.com">contato@barbeariafiladelfo.com.br</a></p>
          <p>WhatsApp: <a href="https://wa.me/5517991297360" target="_blank" rel="noopener noreferrer">(17) 99129-7360</a></p>
        </section>
      </main>
      <footer className="App-footer">
        <p>&copy; 2024 Barbearia Filadelfo. Todos os direitos reservados.</p>
      </footer>
    </div>
  );
}

export default Home;
